import React, { useRef, useState, useContext, useEffect } from 'react';

import { useHistory, Link } from "react-router-dom";

import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Box from '@material-ui/core/Box';
import CircularProgress from '@material-ui/core/CircularProgress';
import Alert from '@material-ui/lab/Alert';
import axios from 'axios';
import { AuthContext } from "../../components/Auth/AuthDataProvider.jsx";
import authApi from '../../api/Authentication.jsx';
import apiUtil from '../../api/apiUtil.jsx';
import Card from '../../components/Card/Card.jsx';
import CustomDialog from '../../components/Dialog/CustomDialog.jsx';

// import logo from '../../assets/img/wn_logo.svg';
import logo from '../../assets/img/logo_black.png';
import trackerLogo from '../../assets/img/tracker_logo.png';
import {RoleAccessService} from "./../../data/role-access";

export default function SignIn() {
  let history = useHistory();
  const isCustomerLogin = useRef(false);
  const { clearStorage, token, refreshToken, authenticated, setAuthenticated, setToken, setCurrentRole, setAccessToken, setRefreshToken, setTokenType, setUser, setAppPermission, setUniqueRolePermissions } = useContext(AuthContext);
  
  const [validatedEmail, setValidatedEmail] = useState(false);
  const [errorEmail, setErrorEmail] = useState(null);
  const [validatedResetEmail, setValidatedResetEmail] = useState(false);
  const [errorResetEmail, setErrorResetEmail] = useState(null);
  const [validatedPass, setValidatedPass] = useState(false);
  const [errorPass, setErrorPass] = useState(null);
  const [error, setError] = useState(null);
  const [mailInput, setMailInput] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [showForgotPw, setShowForgotPw] = useState(false);
  const [sentEamil, setSentEmail] = useState(null);
  const [resetPassword, setResetPassword] = useState('');
  const [setPassword, setSetPassword] = useState('');
  const [errorConfirm, setErrorConfirm] = useState('');
  const [timer, setTimer] = useState(false);
  const [popUpAlert, setPopUpAlert] = useState(false);
  const [showResetPass, setShowResetPass] = useState(true);
  const [showDialog, setShowDialog] = useState(false);
  const [showResetDialog, setResetShowDialog] = useState(false);
  const [showDialogActive, setShowDialogActive] = useState(false);
  const [passwordValidated, setPasswordValidated] = useState(false);
  const [passwordSuccess , setPasswordSuccess] = useState(false)

  const isMounted = useRef(null);
  const emailInput = useRef();
  const resetEmailInput = useRef();
  
  const pswInput = useRef();
  const axiosRef = useRef();


  const getQueryStringParams = (query) => {
    return query
        ? (/^[?#]/.test(query) ? query.slice(1) : query)
            .split(';')
            .reduce((params, param) => {
                    let [key, value] = param.split('=');
                    params[key] = value ? decodeURIComponent(value.replace(/\+/g, ' ')) : '';
                    return params;
                }, {}
            )
        : {}
};

let url = getQueryStringParams(window.location.pathname);

  const show = url.show;
  const email = url.email;
  const resetToken = url.token;
  const setPasswordId = url.id;
  const admin_id = url.admin_id
  const ref_company_id = url.ref_company_id

  useEffect(() => {
    //check if authenticated
    // checkToken();
    let isMounted = true;

    if (isMounted) {
      if(show == "setpassword") {
        clearStorage();
        window.localStorage.setItem('authenticatedAuth', null);
      }
      if(authenticated && show != "setpassword"){
          history.push("/");
      }

      // executed when component mounted
      axiosRef.current = apiUtil.getCancelToken();
      // isMounted.current = true;

      if(timer == true){
        setTimeout(() => { 
          window.location.href ="Sign-in";
      }, 5000)
      }
    }
    return () => {
      // executed when unmount
      isMounted = false;
      // isMounted.current = false;
      axiosRef.current.cancel();
    }
  }, [timer, mailInput, show]);

  const checkToken = () => {
    let tokenObj = apiUtil.getObject(token);
    if(tokenObj && refreshToken){
      var created = new Date(tokenObj.created_at);
      var expired = new Date(created);
      expired.setSeconds(expired.getSeconds() + tokenObj.expires_in);

      if (expired < new Date()) {
        setAuthenticated(false);
        callRefreshTokenApi(refreshToken);
      }
    } else {
      clearStorage();
      return;
    }
  }

  const callRefreshTokenApi = (refreshToken) => {
    authApi.refreshToken(refreshToken)
      .then((result) => {
        apiUtil.parseResult(result, (data) => {
          data.created_at = data.created_at * 1000;
          
          setToken(JSON.stringify(data));
          setAccessToken(data.access_token);
          setRefreshToken(data.refresh_token);
          setTokenType(data.token_type);
          setAuthenticated(true);
        }, (error, type) => {
          clearStorage();
          return;
        });
    });
  }

  const handleSubmit = async e => {
    e.preventDefault();
    e.stopPropagation();
    
    const form = e.currentTarget;

    setError(null);

    setValidatedEmail(false);
    setErrorEmail(null);
    setValidatedPass(false);
    setErrorPass(null);
    
    if(emailInput.current.lastChild.firstChild.value === ''){
      setValidatedEmail(true);
      setErrorEmail('Please enter your email address.');
    }

    if(pswInput.current.lastChild.firstChild.value === ''){
      setValidatedPass(true);
      setErrorPass('Please enter your password.');
    }

    if (form.checkValidity() === true) {
      setIsLoading(true);

      authApi.login(emailInput.current.lastChild.firstChild.value, pswInput.current.lastChild.firstChild.value, axiosRef.current.token, admin_id, ref_company_id)
        .then((result) => {
          apiUtil.parseResult(result, (data) => {
            if(data != null) {
              let appAccessPermissions = [];

              if (data && data.owner_info.application_registrations.length > 0) {
                data.owner_info.application_registrations.forEach((el) => {
                  if (el.name === "AUTH") {
                    appAccessPermissions.push("auth");
                  } else if (el.name === "WLS") {
                    appAccessPermissions.push("wls");
                  } else if (el.name === "WMS") {
                    appAccessPermissions.push("wms");
                  }
                });

                window.localStorage.setItem("appPermission", appAccessPermissions);
                window.localStorage.setItem("password", window.btoa(pswInput.current.lastChild.firstChild.value));
              }


              //Grouping the permissions of associated Users
              let uniqueRolePermissions = []
              if (data && data.owner_info && data.owner_info.roles[0].permissions.length > 0) {
                // uniqueRolePermissions = [...new Set(data.owner_info.roles[0].permissions.map(item => item.group))]; // [ 'A', 'B']
                uniqueRolePermissions = data.owner_info.roles[0].permissions; // [ 'A', 'B']
                
                window.localStorage.setItem("uniqueRolePermissions", JSON.stringify(uniqueRolePermissions));
              }

              setAuthenticated(true);
              setCurrentRole(data.owner_info.roles[0] !== undefined ? data.owner_info.roles[0].role_name : null);
              setAccessToken(data.token.access_token);
              setRefreshToken(data.token.refresh_token);
              setTokenType(data.token.token_type);
              setToken(JSON.stringify(data.token));
              setUser(JSON.stringify(data));
              setAppPermission(appAccessPermissions);
              setUniqueRolePermissions(uniqueRolePermissions);

              
              let role = localStorage.getItem('current_role');
              let grantedMenu = new RoleAccessService;

              // console.log('role', role);
              if (role === 'Super Admin Role' || role === 'Admin Template Roles' ) {
                  history.push("/");
              } else {
                clearStorage();
                setPopUpAlert(true);
                setIsLoading(false);
              }
            } else {
              setError({ title: error });
              setIsLoading(false);
            }
            
          }, (error, type) => {
            setError({ title: error });
            setIsLoading(false);
          });
      });
    }
  };
  

  const handleResetSubmit = async e => {
    e.preventDefault();
    e.stopPropagation();
    
    const form = e.currentTarget;

    setError(null);

    setValidatedResetEmail(false);
    setErrorResetEmail(null);
    setSentEmail(null)
    if(resetEmailInput.current.lastChild.firstChild.value === ''){
      setValidatedResetEmail(true);
      setErrorResetEmail('Please enter your email address.');
    } else {
      let email = resetEmailInput.current.lastChild.firstChild.value
      let param ={
        email
      }
      setIsLoading(true);
      authApi.validEmail('validate/email', param)
      .then((result) => {
      apiUtil.parseResult(result, (data) => {
        callForgotPasswordApi(email)
        setIsLoading(false);
      }, (error, type) => {
        console.log("error",error)
        setErrorResetEmail(error);
        setIsLoading(false);
      });
      });
    }
  }

  const handleResetExpire = (email) => {
    setError(null);

    setValidatedResetEmail(false);
    setErrorResetEmail(null);
    setSentEmail(null)
      let param ={
        email : email
      }
      setIsLoading(true);
      authApi.validEmail('validate/email', param)
      .then((result) => {
      apiUtil.parseResult(result, (data) => {
        callForgotPasswordApi(email)
        setIsLoading(false);
      }, (error, type) => {
        console.log("error",error)
        setErrorResetEmail(error);
        setIsLoading(false);
      });
      });
    }
  
  const resetSubmit = async e => {
    e.preventDefault();
    e.stopPropagation();
    
    if (resetPassword == '') {
      setValidatedPass(true)
      return
    }

    let errorMessage = "";
    let status = 0;
    const { 
      REACT_APP_AUTH_API_URL
    } = process.env;
    const PATH = 'admin/api/';
    const api = "admins/undefined/password";
    const URL = REACT_APP_AUTH_API_URL + PATH + api;
   
    let param = {
      email: email,
      token: resetToken,
      password: resetPassword,
      password_confirmation: resetPassword
    };
    axios.put(
      // "https://auth-api.worknode.ai/admin/api/application_companies?search=&page=1&take=10&sorting_array[]=created_at%20DESC",
      URL,
      param,
       { 
          headers: {
              'Content-Type': 'application/json',
          }
      })
      .catch( error => {
          try {
              status = error.response.status;
              if(status == 400 || status == 404) {
                setResetShowDialog(true);
              } else {
              if (error.response) {
                  // Request made and server responded
                  errorMessage = error.response.data;
              } else if (error.request) {
                  // The request was made but no response was received
                  errorMessage = error.request;
              } else {
                  // Something happened in setting up the request that triggered an Error
                  errorMessage = error.message;
              }
            }
          } catch(err) {
              errorMessage = 'Service Unavailable!'
          }
      }).then((response) => {
        if (response) {
          setIsLoading(true);
    
          authApi.login(email, resetPassword, axiosRef.current.token)
            .then((result) => {
              apiUtil.parseResult(result, (data) => {
                if(data != null) {
                  let appAccessPermissions = [];
    
                  if (data && data.owner_info.application_registrations.length > 0) {
                    data.owner_info.application_registrations.forEach((el) => {
                      if (el.name === "AUTH") {
                        appAccessPermissions.push("auth");
                      } else if (el.name === "WLS") {
                        appAccessPermissions.push("wls");
                      } else if (el.name === "WMS") {
                        appAccessPermissions.push("wms");
                      }
                    });
    
                    window.localStorage.setItem("appPermission", appAccessPermissions);
                  }
    
    
                  //Grouping the permissions of associated Users
                  let uniqueRolePermissions = []
                  if (data && data.owner_info && data.owner_info.roles[0].permissions.length > 0) {
                    // uniqueRolePermissions = [...new Set(data.owner_info.roles[0].permissions.map(item => item.group))]; // [ 'A', 'B']
                    uniqueRolePermissions = data.owner_info.roles[0].permissions; // [ 'A', 'B']
                    
                    window.localStorage.setItem("uniqueRolePermissions", JSON.stringify(uniqueRolePermissions));
                  }
    
                  setAuthenticated(true);
                  setCurrentRole(data.owner_info.roles[0] !== undefined ? data.owner_info.roles[0].role_name : null);
                  setAccessToken(data.token.access_token);
                  setRefreshToken(data.token.refresh_token);
                  setTokenType(data.token.token_type);
                  setToken(JSON.stringify(data.token));
                  setUser(JSON.stringify(data));
                  setAppPermission(appAccessPermissions);
                  setUniqueRolePermissions(uniqueRolePermissions);

                  let role = localStorage.getItem('current_role');

                  if (role === 'Super Admin Role' || role === 'Admin Template Roles' ) {                    
                    history.push("/");
                  } else {
                    let fms_link = ""
                    if(window.location.hostname === 'localhost' || window.location.hostname.includes('staging')) {
                      fms_link = "https://staging-fms.evfy.sg/"
                    } else {
                      fms_link = "https://fms.evfy.sg/"
                    }
                    clearStorage();
                    setIsLoading(false);
                    window.location.replace(fms_link);
                  }
                } else {
                  setError({ title: error });
                  setIsLoading(false);
                }
                
              }, (error, type) => {
                setError({ title: error });
                setIsLoading(false);
              });
          });
        }
      });
  }

  const setPasswordSubmit = async e => {
    e.preventDefault();
    e.stopPropagation();

    if (setPassword == '') {
      setPasswordValidated(true)
      return
    }

    let errorMessage = "";
    let status = 0;
    const { 
      REACT_APP_AUTH_API_URL
    } = process.env;
    const PATH = 'admin/api/';
    const api = "admins/"+ setPasswordId + "/password";
    const URL = REACT_APP_AUTH_API_URL + PATH + api;
    let param = {
      email: email,
      token: resetToken,
      password: setPassword,
      password_confirmation: setPassword
    };
    axios.put(
      // "https://auth-api.worknode.ai/admin/api/application_companies?search=&page=1&take=10&sorting_array[]=created_at%20DESC",
      URL,
      param,
       { 
          headers: {
              'Content-Type': 'application/json',
          }
      })
      .catch( error => {
          try {
              status = error.response.status;
              
              if(status == 404 || status == 400) {
                setShowDialog(true);
              } else {
              if (error.response) {
                  // Request made and server responded
                  errorMessage = error.response.data;
              } else if (error.request) {
                  // The request was made but no response was received
                  errorMessage = error.request;
              } else {
                  // Something happened in setting up the request that triggered an Error
                  errorMessage = error.message;
              }
            }
          } catch(err) {
              errorMessage = 'Service Unavailable!'
          }
      }).then((response) => {
        if (response) {
          // setIsLoading(true);
    
          setPasswordSuccess(true)
          authApi.login(email, setPassword, axiosRef.current.token)
            .then((result) => {
              apiUtil.parseResult(result, (data) => {
                if(data != null) {
                  let role_name = data.owner_info.roles[0] !== undefined ? data.owner_info.roles[0].role_name : null

                  if (apiUtil.isAdminRole(role_name) == false ) {     
                    if(window.location.hostname === 'localhost' || window.location.hostname.includes('staging')) {
                      window.location.replace("https://staging-fms.evfy.sg/Sign-in")
                    } else {
                      window.location.replace("https://fms.evfy.sg/Sign-in")
                    }
                  }                     
                } else {
                  setError({ title: error });
                  setIsLoading(false);
                }
             
              }, (error, type) => {
                setError({ title: error });
                setIsLoading(false);
              });
          });

          // authApi.login(email, setPassword, axiosRef.current.token)
          //   .then((result) => {
          //     apiUtil.parseResult(result, (data) => {
          //       if(data != null) {
          //         let appAccessPermissions = [];
    
          //         if (data && data.owner_info.application_registrations.length > 0) {
          //           data.owner_info.application_registrations.forEach((el) => {
          //             if (el.name === "AUTH") {
          //               appAccessPermissions.push("auth");
          //             } else if (el.name === "WLS") {
          //               appAccessPermissions.push("wls");
          //             } else if (el.name === "WMS") {
          //               appAccessPermissions.push("wms");
          //             }
          //           });
    
          //           window.localStorage.setItem("appPermission", appAccessPermissions);
          //         }
    
    
          //         //Grouping the permissions of associated Users
          //         let uniqueRolePermissions = []
          //         if (data && data.owner_info && data.owner_info.roles[0].permissions.length > 0) {
          //           // uniqueRolePermissions = [...new Set(data.owner_info.roles[0].permissions.map(item => item.group))]; // [ 'A', 'B']
          //           uniqueRolePermissions = data.owner_info.roles[0].permissions; // [ 'A', 'B']
                    
          //           window.localStorage.setItem("uniqueRolePermissions", JSON.stringify(uniqueRolePermissions));
          //         }

          //         let role_name = data.owner_info.roles[0] !== undefined ? data.owner_info.roles[0].role_name : null
          //         if (apiUtil.isAdminRole(role_name) ) {
          //           setAuthenticated(true);
          //           setCurrentRole(data.owner_info.roles[0] !== undefined ? data.owner_info.roles[0].role_name : null);
          //           setAccessToken(data.token.access_token);
          //           setRefreshToken(data.token.refresh_token);
          //           setTokenType(data.token.token_type);
          //           setToken(JSON.stringify(data.token));
          //           setUser(JSON.stringify(data));
          //           setAppPermission(appAccessPermissions);
          //           setUniqueRolePermissions(uniqueRolePermissions);

          //           history.push("/");
      
          //           // let role = localStorage.getItem('current_role');
          //           // let grantedMenu = new RoleAccessService;
      
          //           // if (role === 'Customer Template Role' || !grantedMenu.isShouldShowMenu(role,"dashboard")) {
          //           //   history.push("/job-list");
          //           // } else {
          //           //   history.push("/");
          //           // }
          //         } else {
                    
          //           if(window.location.hostname === 'localhost' || window.location.hostname.includes('staging')) {
          //             window.location.replace("https://staging-fms.evfy.sg/Sign-in")
          //           } else {
          //             window.location.replace("https://fms.evfy.sg/Sign-in")
          //           }
                    
          //         }

          //       } else {
          //         setError({ title: error });
          //         setIsLoading(false);
          //       }
                
          //     }, (error, type) => {
          //       setError({ title: error });
          //       setIsLoading(false);
          //     });
          // });
        }
      });
  }

  const resendConfirmation = (email)=> {
    let errorMessage = "";
    let status = 0;
    const { 
      REACT_APP_AUTH_API_URL
    } = process.env;
    const PATH = 'admin/api/';
    const api = "resend/welcome/activation";
    const URL = REACT_APP_AUTH_API_URL + PATH + api;

    let param = {
      email: email,
    };
    axios.post(
      // "https://auth-api.worknode.ai/admin/api/application_companies?search=&page=1&take=10&sorting_array[]=created_at%20DESC",
      URL,
      param,
       { 
          headers: {
              'Content-Type': 'application/json',
          }
      })
      .catch( error => {
          try {
              status = error.response.status;
              if (error.response) {
                  // Request made and server responded
                  errorMessage = error.response.data;
                  setErrorConfirm(error.response.data.result);
                  setShowDialogActive(true);
              } else if (error.request) {
                  // The request was made but no response was received
                  errorMessage = error.request;
              } else {
                  // Something happened in setting up the request that triggered an Error
                  errorMessage = error.message;
              }
          } catch(err) {
              errorMessage = 'Service Unavailable!'
          }
      }).then((response) => {
        if (response) {
          console.log("response",  response.data.result)

        }
      });
  }

  const callForgotPasswordApi = email => {
    let param = {
      proper_mail: email,
      email
    }
    setIsLoading(true);
      authApi.validEmail('forgot/password', param)
      .then((result) => {

      apiUtil.parseResult(result, (data) => {
        setSentEmail('Please check your email for a message with your code')
        setIsLoading(false)
        setTimer(true)
        setMailInput(" ");
        console.log("timer", timer);
      }, (error, type) => {
        setIsLoading(false);
      });
      });
  }

  const Dialog = () => {
    return <CustomDialog 
   open={showDialog}
   title={'Confirmation expired'}
   onClose={() => {
     setShowDialog(false);
   }}
   onOk={() => {
     resendConfirmation(email);
     setShowDialog(false);
   }}
 >
   <Box >
    <p>This happenned because you didn't confirm your email & start Worknode within 24 hours when we sent 
      you the comfirmation mail.</p>
    <Box textAlign={'center'}>
    <p>Would you like to resend Comfirmation Email to <strong>{email}</strong></p>
    {errorConfirm != ''? <Box>{errorConfirm}</Box>: ''}
    </Box>
    </Box>
 </CustomDialog>
 }


 const AuthWarning = () => {
  return <CustomDialog 
 open={popUpAlert}
 title={'Authentication Warning'}
 btnOkText={'Ok'}
 onClose={() => {
  setPopUpAlert(false);
 }}
 
 onOk={() => {
  setPopUpAlert(false);
}}
>
 <Box textAlign={'center'}>
  <p>You don't Have Permission to use Auth Portal !!</p>
  {/* <Box textAlign={'center'}>
  <p>Would you like to resend Comfirmation Email to <strong>{email}</strong></p>
  {errorConfirm != ''? <Box>{errorConfirm}</Box>: ''}
  </Box> */}
  </Box>
</CustomDialog>
}


 const ResetDialog = () => {
  return <CustomDialog 
 open={showResetDialog}
 title={'Confirmation expired'}
 onClose={() => {
   setResetShowDialog(false);
 }}
 onOk={() => {
  handleResetExpire(email);
   setResetShowDialog(false);
 }}
>
 <Box >
  <p>This happenned because you didn't confirm your email & start Worknode within 24 hours when we sent 
    you the comfirmation mail.</p>
  <Box textAlign={'center'}>
  <p>Would you like to resend Comfirmation Email to <strong>{email}</strong></p>
  {errorConfirm != ''? <Box>{errorConfirm}</Box>: ''}
  </Box>
  </Box>
</CustomDialog>
}

 const ActivetedWarningDialog = () => {
  return <CustomDialog 
 open={showDialogActive}
 title={'Confirmation expired'}
 onClose={() => {
   setShowDialogActive(false);
 }}
>
 <Box textAlign={'center'}>
  {errorConfirm != ''? <Box style={{backgroundColor: '#5cb85c', padding: '8px', color:'#FFF', fontSize:'16px'}}>{errorConfirm}</Box>: ''}
  </Box>
</CustomDialog>
}

  let fms_link = ""
  let auth_link = ""
  let wms_link = ""
  if(window.location.hostname === 'localhost' || window.location.hostname.includes('staging')) {
    fms_link = "https://staging-fms.evfy.sg/"
    auth_link = "https://staging-auth.evfy.sg/"
    wms_link = "https://staging-wms.evfy.sg/"
  } else {
    fms_link = "https://fms.evfy.sg/"
    wms_link = "https://wms.evfy.sg/"
    auth_link = "https://auth.evfy.sg/"

}
  return (
    <div className="signin container">
    <div className="login" component="main">
      {/* <div className="top-menu"> */}
        {/* <div className="logo"><img src={trackerLogo} alt="" height="30px" /></div> */}
        {/* <div className="signup-acc"> 
        <div>Don't have an account?</div>
        <Button variant="contained" color="primary" href="/Sign-up">
          Sign Up
        </Button>
        </div> */}
      {/* </div> */}
      {/* {
        error && !isLoading
        &&
        <Box textAlign="left" mt={0} mb={3}>
          <Alert severity="error">
            "Incorrect username or password!"
          </Alert>
        </Box>
      } */}
      {/* {
        errorResetEmail && !isLoading
        &&
        <Box textAlign="left" mt={0} mb={3}>
          <Alert severity="error">
            {errorResetEmail}
          </Alert>
        </Box>
      }
      {
        sentEamil && !isLoading
        &&
        <Box textAlign="left" mt={0} mb={3}>
          <Alert severity="success">
            {sentEamil}
          </Alert>
        </Box>
      } */}
     
      <div className="form-wrapper" >
      {
        error && !isLoading
        &&
        <Box textAlign="left" mt={2} mb={1}>
          <Alert severity="error">
            "Incorrect username or password!"
            {/* {error.title && <b>{error.title}</b>}
            {(error.errors && error.errors.length > 0) && error.errors.map((e, i) => {
              return <div key={i}>{e}</div>
            })} */}
          </Alert>
        </Box>
      }
      {
        errorResetEmail && !isLoading
        &&
        <Box textAlign="left" mt={0} mb={3}>
          <Alert severity="error">
            {errorResetEmail}
          </Alert>
        </Box>
      }
      {
        sentEamil && !isLoading
        &&
        <Box textAlign="left" mt={0} mb={3}>
          <Alert severity="success">
            {sentEamil}
          </Alert>
        </Box>
      }
        {show == "setpassword" ? passwordSuccess ? 
        <Card>
          
        <Box  mb={1}>
        <div className="logo" textAlign="center">
            <img src={trackerLogo} alt="" height="60px" />
          </div>
          <div className="form-title" textAlign="left">
          Set Password
          </div>
          <Alert severity="success">
          Password has been updated!
          </Alert>
          <Box m={2}><a href={fms_link} >Login FMS</a> <a style={{"marginLeft":10}} href={wms_link} >Login WMS</a> <a style={{"marginLeft":10}} href={auth_link} >Login Admin Panel</a> </Box>
          
        </Box>
    </Card>:
        <Card>
        <form noValidate 
        onSubmit={setPasswordSubmit}
        >
        <Box  mb={1}>
        <div className="logo" textAlign="center">
            <img src={trackerLogo} alt="" height="60px" />
          </div>
          <div className="form-title" textAlign="left">
          Set Password
          </div>
          <div className="form-title-inner" textAlign="left">
          Before we begin, let's set your account's password.
          </div>
          <div className="form-title-bg">
            <ul>
              <li>Password must be 8-20 characters long.</li>
              <li>At least one capital letter, a number & a symbol (optional).</li>
            </ul>
          </div>
          <TextField
            variant="outlined"
            margin="normal"
            error={passwordValidated}
            required
            fullWidth
            value={setPassword}
            onChange={(e)=> {
              setSetPassword(e.target.value);
            }}
            name="setpassword"
            label="setPassword"
            type="password"
            id="setpassword"
            // autoComplete="current-password"
            // error={validatedPass} 
            // helperText={errorPass}
            // ref={pswInput}
          />
        </Box>
        <Button
          type="submit"
          fullWidth
          variant="contained" 
          color="primary"
          disabled={isLoading}
        >
          <Box pl={2} pr={2} style={{ color: 'white' }}>Save Password</Box>
          {isLoading && <CircularProgress size={15} style={{ color: 'white' }} />}
        </Button>
        </form>
    </Card> :<>
        {show == "resetpassword"?
        <Card>
            <form noValidate 
            onSubmit={resetSubmit}
            >
            <Box  mb={1}>
            <div className="logo" textalign="center">
                <img src={trackerLogo} alt="" height="60px" />
              </div>
              <div className="form-title" textalign="left">
              Reset Password
              </div>
              <div className="form-title-inner" textAlign="left">
              Welcome. Let's set a new password for your account. Don't lose it this time okay!
              </div>
              <div className="form-title-bg">
                <ul>
                  <li>Password must be 8-20 characters long.</li>
                  <li>At least one capital letter, a number & a symbol (optional).</li>
                </ul>
              </div>
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                value={resetPassword}
                onChange={(e)=> {
                  setResetPassword(e.target.value);
                }}
                name="password"
                label="Password"
                type="password"
                id="password"
                autoComplete="current-password"
                error={validatedPass} 
                helperText={errorPass}
                ref={pswInput}
              />
            </Box>
            <Button
              type="submit"
              fullWidth
              variant="contained" 
              color="primary"
              disabled={isLoading}
            >
              <Box pl={2} pr={2} style={{ color: 'white' }}>Save Password</Box>
              {isLoading && <CircularProgress size={15} style={{ color: 'white' }} />}
            </Button>
            </form>
        </Card>
      :<>
      {showForgotPw === false ? <Card>
      <form noValidate onSubmit={handleSubmit}>

        <Box textAlign="center" mb={1}>
          <div className="form-title">
         Sign In to
          </div>
          <div className="logo">
            <img src={trackerLogo} alt="" height="60px" />
          </div>
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="email"
            label="Email Address"
            name="email"
            autoComplete="email"
            autoFocus
            error={validatedEmail} 
            helperText={errorEmail}
            ref={emailInput}
          />
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="password"
            label="Password"
            type="password"
            id="password"
            autoComplete="current-password"
            error={validatedPass} 
            helperText={errorPass}
            ref={pswInput}
          />
        </Box>
        <Box mb={2}><a className="resetPass" onClick={() => setShowForgotPw(true)}><u>Forgot your Password?</u></a></Box> 
        <Button
          type="submit"
          fullWidth
          variant="contained" 
          color="primary"
          disabled={isLoading}
        >
          <Box pl={2} pr={2} style={{ color: 'white' }}>SIGN IN</Box>
          {isLoading && <CircularProgress size={15} style={{ color: 'white' }} />}
        </Button>
     
      <div className="signup-acc">
        <div>Don't have an account?
        <a variant="contained" color="primary" href="/sign-up">

          Sign Up
        </a> Now!</div>
        </div>
        </form>
      </Card> : (<Card>
        <form onSubmit={handleResetSubmit}><Box textAlign="center" mb={3}>
        <div className="form-title">
        Reset Password
        </div>
        <div className="form-description">
        Enter your email address below and we will guide you on how to reset your password.
        </div>
        <TextField
          variant="outlined"
          margin="normal"
          required
          fullWidth
          id="email"
          label="Email Address"
          name="email"
          autoComplete="email"
          value={mailInput}
          onChange={(e)=> {
            setMailInput(e.target.value);
          }}
          autoFocus
          error={validatedResetEmail} 
          helperText={errorResetEmail}
          ref={resetEmailInput}
        />
      </Box>
      
      <Button
        type="submit"
        fullWidth
        variant="contained" 
        color="primary"
        disabled={isLoading}
      >
        <Box pl={2} pr={2} style={{ color: 'white' }}>Reset Password</Box>
        {isLoading && <CircularProgress size={15} style={{ color: 'white' }} />}
      </Button>
      <Box mt={2}><a className="resetPass" onClick={() => setShowForgotPw(false)}><u>Back to Login</u></a></Box> </form>
      </Card>
      )}
      </>
        }
        </>
      }
      </div>
     
    </div>
    {Dialog()}
    {ActivetedWarningDialog()}
    {ResetDialog()}
    {AuthWarning()}
    </div>
  );
}