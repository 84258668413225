import React, { Component } from 'react';
import BreadCrumb from "../../components/Navs/Breadcrumb"
import { AuthContext } from "../../components/Auth/AuthDataProvider.jsx";
import { 
  Box,
  Grid,
  Button,
} from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import Radio from '@material-ui/core/Radio';
import FormControl  from '@material-ui/core/FormControl';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import CustomInput from '../../components/CustomFields/CustomInput.jsx';
import CustomButton from '../../components/CustomFields/CustomButton.jsx';
import StickyPageHeader from '../../components/PageHeader/StickyPageHeader.jsx';
import Card from '../../components/Card/Card.jsx';
import Table from '../../components/Table/Table.jsx';
import { NavLink } from 'react-router-dom';
import axios from 'axios';
import HtmlParser from 'react-html-parser';
import apiUtil from "../../api/apiUtil.jsx";
import CustomCheckbox from '../../components/CustomFields/CustomCheckbox.jsx';
import authApi from "../../api/api.jsx";
import CustomDialog from '../../components/Dialog/CustomDialog.jsx';

class AddRole extends Component {
  static contextType = AuthContext;

  constructor(props){
    super(props);
    this.refTable = React.createRef();
    this.refTableSearch = React.createRef();

    let hiddenColumns = [];
    let columnHiddenSettingsId = 0;
    let columnHiddenSettings = JSON.parse(localStorage.getItem('job_column_settings'));

    if (columnHiddenSettings !== null) {
      hiddenColumns = columnHiddenSettings.json_data.replace(/[^0-9a-zA-Z-_.,]/g, "").split(",");
      columnHiddenSettingsId = columnHiddenSettings.id ? columnHiddenSettings.id : 0;
    }
    
    let id = (this.props.location && this.props.location.state && this.props.location.state.id && this.props.location.state.id > 0) ? this.props.location.state.id : 0;
    this.state = {
      id: id,
      data : [],
      wls_permission_data: [],
      admin_permission_data: [],
      wms_permission_data: [],
      page : 1,
      total : 2,
      limit : 10,
      switch: 1,
      isLoadingStatus: false,
      pageSize : 22,      // status: [],
      hiddenColumnSettingId: columnHiddenSettingsId,
      hiddenColumns: hiddenColumns,
      role_name: '',
      role_nameValidated: false,
      selected_permission: [],
      selected_permission_group: [],
      wls_group: [],
      admin_group: [],
      wms_group: [],
      isWlsSelected: false,
      isAdminSelected: false,
      isWmsSelected: false,
      openDialog: false, 
      rolesData: [],
      openDialogRole: '',
      is_active: true
    }

   
  }
  componentDidMount() { 
    this.callReadRolesApi();
    this.callReadApi(() => {
      if(this.state.id && this.state.id > 0){ 
        this.callDetailsApi(this.state.id, (data) => {
          let role_name = data.role_name;
          let permissions = data.permissions
          let selected_permission = []
          let selected_permission_group = []
          let wls_group = this.state.wls_group
          let admin_group = this.state.admin_group
          let wms_group = this.state.wms_group
          permissions.map( permission => {
            if(permission.application_registration_id == 2 ) {
              selected_permission.push(permission.id);
              selected_permission_group.push(`WMS ${permission.group}`)
            } else {
              selected_permission.push(permission.id);
              selected_permission_group.push(permission.group)
            }
            
            
          })
          
          this.setState({
            selected_permission: selected_permission,
            role_name: role_name,
            selected_permission_group: selected_permission_group,
            isWlsSelected: this.checkSelected(wls_group, selected_permission_group),
            isAdminSelected: this.checkSelected(admin_group, selected_permission_group),
            isWmsSelected: this.checkSelected(wms_group, selected_permission_group),
            is_active: data.is_active
          })
  
        })
      }
    });

    
  }

  callReadApi(callback){
    let errorMessage = "";
    let status = 0;
    const { 
      REACT_APP_AUTH_API_URL
    } = process.env;
    const PATH = 'admin/api/';
    const api = "permissions";
    const URL = REACT_APP_AUTH_API_URL + PATH + api;
    const { accessToken } = this.context;

    let param = {
      page: this.state.page,
      take: this.state.limit,
      search: "",
      group: "application_registrations",
      // start_date: startDate,
      // end_date: endDate,
      sorting_array: ['created_at DESC'],
    };
    axios.get(
      URL,
       { 
          params: param,
          data: param,
          headers: {
              'Content-Type': 'application/json',
              'Authorization': 'Bearer ' + accessToken
          }
      })
      .catch( error => {
          try {
              status = error.response.status;

              if (error.response) {
                  // Request made and server responded
                  errorMessage = error.response.data;
              } else if (error.request) {
                  // The request was made but no response was received
                  errorMessage = error.request;
              } else {
                  // Something happened in setting up the request that triggered an Error
                  errorMessage = error.message;
              }
          } catch(err) {
              errorMessage = 'Service Unavailable!'
          }
      }).then((response) => {
        // console.log(response);
        var json_data = response.data.result;
          // var result = [];
          // for (var i in json_data) {
          //   result[i] = json_data[i].permissions;
          // }

          // var json_data__ = result;
        let wls = [];
        let admin = [];
        let wms = [];
        let wls_group = []
        let admin_group = []
        let wms_group = []
        json_data.forEach(dd => {
          if (dd.application_registration.name == "WLS") {
            wls = dd.permissions
            wls.forEach(e => {
              wls_group.push(e.name)
            })
          } else if (dd.application_registration.name == "AUTH") {
            admin = dd.permissions
            admin.forEach(e => {
              admin_group.push(e.name)
            }) 
          } else if (dd.application_registration.name == "WMS") {
            wms =dd.permissions
            wms.forEach(e => {
              wms_group.push(`WMS ${e.name}`)
            }) 
          }
        })
          // for (var i in json_data__) {
          //   wls = json_data__[1];
          //   admin = json_data__[2];
          //   wms = json_data__[0];
          // }
        
          // wls.forEach(e => {
          //   wls_group.push(e.name)
          // }) 
          // admin.forEach(e => {
          //   admin_group.push(e.name)
          // }) 
          // wms.forEach(e => {
          //   wms_group.push(`WMS ${e.name}`)
          // }) 
        if(response != null){
        this.setState({
          data: response.data.result,
          total: response.data.total,
          isLoading: false,
          wls_permission_data: wls,
          admin_permission_data: admin,
          wls_group: wls_group,
          admin_group: admin_group,
          wms_permission_data: wms,
          wms_group: wms_group
        }, () => {
          callback();
        });
        
        }
       
      });
  }

  callReadRolesApi = ( callback = null) => {
    const { accessToken } = this.context;

    this.setState({
      isLoading: true
    }, () => {
      authApi.read('roles/', null, accessToken)
        .then((result) => {
          apiUtil.parseResult(result, (data) => {
            this.setState({
              isLoading: false,
              rolesData: data.result,
            }, () => {
              if(callback){
                callback((data && data.result) ? data.result : null);
              }
            });
          }, (error, type) => {
            this.setState({
              isLoading: false,
            }, () => {
              if(callback){
                callback(null, []);
              }
            });
          });
      });
    });

  }
  callDetailsApi = (id = '', callback = null) => {
    const { accessToken } = this.context;

    this.setState({
      isLoading: true
    }, () => {
      authApi.read('roles/' + id, null, accessToken)
        .then((result) => {
          apiUtil.parseResult(result, (data) => {
            this.setState({
              isLoading: false,
            }, () => {
              if(callback){
                callback((data && data.result) ? data.result : null);
              }
            });
          }, (error, type) => {
            this.setState({
              isLoading: false,
            }, () => {
              if(callback){
                callback(null, []);
              }
            });
          });
      });
    });
  }

  callCreateApi = (row, callback = null) => {
    const accessToken = window.localStorage.getItem('access_token');

    this.setState({
      isLoading: true
    }, () => {
      authApi.create('roles', row, accessToken)
        .then((result) => {
          apiUtil.parseResult(result, (data) => {
            this.setState({
              isLoading: false,
            }, () => {
              if(callback){
                callback();
              }
            });
          }, (error, type) => {
            this.setState({
              isLoading: false,
            }, () => {
              if(callback){
                callback();
              }
            });
          });
      });
    });
  }

  callUpdateApi = (id, row, callback = null) => {
    const accessToken = window.localStorage.getItem('access_token');

    this.setState({
      isLoading: true
    }, () => {
      authApi.update('roles/' + id, row, accessToken)
        .then((result) => {
          apiUtil.parseResult(result, (data) => {
            this.setState({
              isLoading: false,
            }, () => {
              if(callback){
                callback();
              }
            });
          }, (error, type) => {
            this.setState({
              isLoading: false,
            }, () => {
              if(callback){
                callback();
              }
            });
          });
      });
    });
  }

  handleSubmit = (e) => {
    e.preventDefault();
    e.stopPropagation();
    
    this.setState({
        isLoading: true,
    });

    const form = e.currentTarget;


    this.setState({
      role_nameValidated: false,
    });
    
    if(this.state.role_name === ''){
      this.setState({
        role_nameValidated: true
      });
    }

    if(this.state.selected_permission.length < 1){
      this.setState({
        isLoading: false
      }, () => {
        apiUtil.toast('You must choose a permission!');
      });
      return;
    }


    if (form.checkValidity() === true) {
      let selecteedPermissions = this.state.selected_permission;
      let data = {
        name: this.state.role_name,
        permission_ids: selecteedPermissions.toString(),
        is_active: this.state.is_active,
      };

      if(this.state.id && this.state.id > 0){
        this.callUpdateApi(this.state.id, data, () => {
          this.setState({
            isLoading: false
          }, () => {
            this.props.history.push({
              pathname: '/accounts/all-role',
              state: {
                isShouldDisableSocketToast: true
              }
            });
            apiUtil.toast('Successfully Updated', 'check_circle');
          });
        });
      } else {

        this.callCreateApi(data, () => {
          this.setState({
            isLoading: false
          }, () => {
            this.props.history.push({
              pathname: '/accounts/all-role',
              state: {
                isShouldDisableSocketToast: true
              }
            });
            apiUtil.toast('Successfully Created', 'check_circle');
          });
        });
      }
      
      
    } else {
      this.setState({
        isLoading: false
      });
    }
  }

  checkSelected = (group, selected_group) =>{
    
    let isTrue = false
    selected_group.forEach(g => {
      if (group.includes(g)) {
        isTrue = true  
        return
      }
    })
    return isTrue
  }
  multiCheckbox = (checked, group, type) => {
    let selected_permission_group = this.state.selected_permission_group;
    let datas = (type === "wls")? this.state.wls_permission_data :  (type === "admin") ? this.state.admin_permission_data : this.state.wms_permission_data;
    let selected_permission = this.state.selected_permission;
    if (group) {
      let ids = []
      datas.forEach((data) => {
        
        
        
        if (type == 'wms') {
          if(`WMS ${data.name}` === group[0]) {
            data.permissions.forEach(p => ids.push(p.id))
          } 
        } else {
          if(data.name === group[0]) {
            data.permissions.forEach(p => ids.push(p.id))
          } 
        }
      })

      if( checked === true ){
        ids.forEach(id => {
          selected_permission_group = selected_permission_group.concat(group);
        }) 
        selected_permission = selected_permission.concat(ids);

      } else {
        // for(let i = 0; i  < ids.length; i++) {
        //   group.forEach(g => {
        //   let index = selected_permission_group.indexOf(g);
        //   if (index !== -1) {
        //     selected_permission_group.splice(index, 1);
        //   }
        //   });
        // }
        

        ids.forEach(id => {
          group.forEach(g => {
            let index = selected_permission_group.indexOf(g);
            if (index !== -1) {
              selected_permission_group.splice(index, 1);
            }
          });

          let index = selected_permission.indexOf(id);
          if (index !== -1) {
            selected_permission.splice(index, 1);
          }
        });

      }
    }
    
    this.setState({
      selected_permission: selected_permission,
      selected_permission_group: selected_permission_group,
      isWlsSelected: this.checkSelected(this.state.wls_group, selected_permission_group),
      isAdminSelected: this.checkSelected(this.state.admin_group, selected_permission_group),
      isWmsSelected: this.checkSelected(this.state.wms_group, selected_permission_group),
    });
  }

  topCheckbox = (checked,type) => {
    let selected_permission_group = this.state.selected_permission_group ? this.state.selected_permission_group : [] ;
    let datas = (type === "wls")? this.state.wls_permission_data :  (type === "admin") ? this.state.admin_permission_data : this.state.wms_permission_data;
    let selected_permission = this.state.selected_permission ? this.state.selected_permission : [];
    if(datas) {
      let group = []
      let ids = []
      datas.forEach(data => {
        data.permissions.forEach(p => {
          if (type == 'wms') {
            group.push(`WMS ${data.name}`)
          } else {
            group.push(data.name)
          }
          
          ids.push(p.id)
        })
      })
      if (selected_permission_group.length > 0 ) {
        group.forEach(g => {
          if(selected_permission_group.includes(g)){
            let index = selected_permission_group.indexOf(g)
            if (index !== -1) {
              selected_permission_group.splice(index, 1);
            }

          }
        })
      }

      if (selected_permission.length > 0 ) {
        ids.forEach(p => {
          if(selected_permission.includes(p)){
            let index = selected_permission.indexOf(p)
            if (index !== -1) {
              selected_permission.splice(index, 1);
            }

          }
        })
      }
      if(checked === true) {
        selected_permission = selected_permission.concat(ids)
        selected_permission_group = selected_permission_group.concat(group)
      }

    }
    this.setState({
      selected_permission: selected_permission,
      selected_permission_group: selected_permission_group,
      isWlsSelected: this.checkSelected(this.state.wls_group, selected_permission_group),
      isAdminSelected: this.checkSelected(this.state.admin_group, selected_permission_group),
    });
  }

  toggleCheckbox = (checked, columns , permission_group) => {
    let selected_permission_group = this.state.selected_permission_group ? this.state.selected_permission_group : [];
    let selected_permission = this.state.selected_permission ? this.state.selected_permission : [] ;
    
    if (columns) {

      if (checked === true) { // hide column
        if(columns == 1183 && !(selected_permission.includes(1182))) {
          selected_permission = selected_permission.concat(columns);
          selected_permission_group.push(permission_group);
          selected_permission = selected_permission.concat(1182);
          selected_permission_group.push(permission_group);

        } else if(columns == 1178 && !(selected_permission.includes(1179))) {
          selected_permission = selected_permission.concat(columns);
          selected_permission_group.push(permission_group);
          selected_permission = selected_permission.concat(1179);
          selected_permission_group.push(permission_group);

        }  else if(columns == 48 && !(selected_permission.includes(5))) {
          selected_permission = selected_permission.concat(columns);
          selected_permission_group.push(permission_group);
          selected_permission = selected_permission.concat(5);
          selected_permission_group.push(permission_group);

        } else if(columns == 26 && !(selected_permission.includes(25))) {
          selected_permission = selected_permission.concat(columns);
          selected_permission_group.push(permission_group);
          selected_permission = selected_permission.concat(25);
          selected_permission_group.push(permission_group);

        } else if(columns == 1208 && !(selected_permission.includes(1206))) {
          selected_permission = selected_permission.concat(columns);
          selected_permission_group.push(permission_group);
          selected_permission = selected_permission.concat(1206);
          selected_permission_group.push(permission_group);

        } else if(columns == 263 && !(selected_permission.includes(264))) {
          selected_permission = selected_permission.concat(columns);
          selected_permission_group.push(permission_group);
          selected_permission = selected_permission.concat(264);
          selected_permission_group.push(permission_group);

        } else if(columns == 71 && !(selected_permission.includes(72))) {
          selected_permission = selected_permission.concat(columns);
          selected_permission_group.push(permission_group);
          selected_permission = selected_permission.concat(72);
          selected_permission_group.push(permission_group);

        } else if(columns == 177 && !(selected_permission.includes(224))) {
          selected_permission = selected_permission.concat(columns);
          selected_permission_group.push(permission_group);
          selected_permission = selected_permission.concat(224);
          selected_permission_group.push(permission_group);

        } else if(columns == 1173 && !(selected_permission.includes(1172))) {
          selected_permission = selected_permission.concat(columns);
          selected_permission_group.push(permission_group);
          selected_permission = selected_permission.concat(1172);
          selected_permission_group.push(permission_group);

        } else {
        selected_permission = selected_permission.concat(columns);
        selected_permission_group.push(permission_group)
        } 
      } else { // show column
          
          columns.forEach(column => {
            let index = selected_permission.indexOf(column);
          if (index !== -1) {
            selected_permission.splice(index, 1);
          }
          });
          let index = selected_permission_group.indexOf(permission_group)
          if (index !== -1) {
            selected_permission_group.splice(index, 1);
          }
      }
    }

    this.setState({
      selected_permission: selected_permission,
      selected_permission_group: selected_permission_group,
      isWlsSelected: this.checkSelected(this.state.wls_group, selected_permission_group),
      isAdminSelected: this.checkSelected(this.state.admin_group, selected_permission_group),
      isWmsSelected: this.checkSelected(this.state.wms_group, selected_permission_group),
    });
  }
  
  handleChange = (e) => {
    
    this.setState({
      openDialogRole:e,
    })
  }
  duplicateRole = (role) => {
    let roleDatas = this.state.rolesData;
    let permissions = []
    let selected_permission = []
    let selected_permission_group = []
    if(role === '') {
       apiUtil.toast('Please choose a Role', 'check_circle')
       return
    }
    roleDatas.forEach(data =>{
      if(data.role_name === role) {
        permissions = data.permissions
      }
    })
    
    permissions.map( permission => {
      selected_permission.push(permission.id);
      selected_permission_group.push(permission.group)
      
    })
    // apiUtil.toast('You must type Role name', 'check_circle')
    this.setState({
      selected_permission: selected_permission,
      selected_permission_group: selected_permission_group,
      isWlsSelected: this.checkSelected(this.state.wls_group, selected_permission_group),
      isAdminSelected: this.checkSelected(this.state.admin_group, selected_permission_group),
    });
  }

  customStickyPageHeader = () => {
    return <Box clone pl={4} pr={4} height={'100%'} alignItems={'center'}>
      <Grid container>
        <BreadCrumb />
      </Grid>
    </Box>
  }
  customDialog = () => {
    return <CustomDialog 
      open={this.state.openDialog}
      title={'Duplicate Role from...'}
      onClose={() => {
        this.setState({
          openDialog: false,
        });
      }}
      onOk={() => {
        let role = this.state.openDialogRole;
        this.duplicateRole(role)
        this.setState({
          openDialog: false,
        });
        
      }}
    >
      
      <Box>
        {this.state.rolesData.map((data) => (
          
          <RadioGroup
          name="radio-buttons-group"
          value={this.state.openDialogRole}
          onChange={(e) => this.handleChange(e.target.value)}
          >
          <FormControlLabel value={data.role_name} control={<Radio />} label={data.role_name}  />
        </RadioGroup>
      ))}
      </Box>
    </CustomDialog>
  }

  customTable = () => {

    return <div class="container mt-5">
    <div>
        <div lg={6}>
              <div className="check-main-group">
                <div className={"check-group " + (this.state.switch == 1 ? 'active' : '')}>
                  <Button onClick={() => {this.setState({switch: 1})}}>
                    <CustomCheckbox checked={this.state.isWlsSelected} onChange={(checked) => this.topCheckbox(checked, 'wls')} /> <label class="form-check-label">FMS</label> 
                  </Button>
                </div>
                <div className={"check-group " + (this.state.switch == 2 ? 'active' : '')}>
                  <Button onClick={() => {this.setState({switch: 2})}}>
                    <CustomCheckbox checked={this.state.isAdminSelected}  onChange={(checked) =>this.topCheckbox(checked, 'admin')} /> <label class="form-check-label">ADMIN</label>
                  </Button>
                </div>
                <div className={"check-group " + (this.state.switch == 3 ? 'active' : '')}>
                  <Button onClick={() => {this.setState({switch: 3})}}>
                      <CustomCheckbox checked={this.state.isWmsSelected}  onChange={(checked) =>this.topCheckbox(checked, 'wms')} /> <label class="form-check-label">WMS</label>
                  </Button>
                </div>
              </div>
              {(this.state.switch == 1) && (
                this.state.wls_permission_data.map((data) => (
                  
              <fieldset class="form-group mb-3">
              <Grid container>
                <Grid item lg={12} className="checkbox-head"><div className="checkbox-head-div">
                  <CustomCheckbox checked={this.state.selected_permission_group.length > 0 ? this.state.selected_permission_group.includes(data.name): false} label={data.name} onChange={(checked) => this.multiCheckbox(checked, [data.name], "wls")} />
                  </div></Grid>
                {data.permissions.map((res) => (
                    <Grid item lg={4} className="checkbox-body">
                      <div className="checkbox-body-div">
                            <CustomCheckbox checked={this.state.selected_permission.length > 0 ? this.state.selected_permission.includes(res.id): false} label={res.name == 'Company' ? 'Client Company' : res.name} onChange={(checked) => this.toggleCheckbox(checked, [res.id],data.name)} />
                        </div> 
                    </Grid>
                ))}
                </Grid>
                </fieldset>
                ))
                )}
                {(this.state.switch == 2) && (
                  this.state.admin_permission_data.map((data) => (
                    <fieldset class="form-group mb-3">
                    <Grid container>
                      <Grid item lg={12} className="checkbox-head"><div className="checkbox-head-div">
                        <CustomCheckbox checked={this.state.selected_permission_group.length > 0 ? this.state.selected_permission_group.includes(data.name): false} label={data.name} onChange={(checked) => this.multiCheckbox(checked, [data.name], "admin")} /> 
                        </div></Grid>
                      {data.permissions.map((res) => (
                          <Grid item lg={4} className="checkbox-body">
                            <div className="checkbox-body-div">     
                            <CustomCheckbox checked={this.state.selected_permission.length > 0 ? this.state.selected_permission.includes(res.id): false} label={res.name} onChange={(checked) => this.toggleCheckbox(checked, [res.id], data.name)} />
                            </div>
                          </Grid>
                      ))}
                      </Grid>
                      </fieldset>
                )
              ))}
              {(this.state.switch == 3) && (
                  this.state.wms_permission_data.map((data) => (
                    <fieldset class="form-group mb-3">
                    <Grid container>
                      <Grid item lg={12} className="checkbox-head"><div className="checkbox-head-div">
                        <CustomCheckbox checked={this.state.selected_permission_group.length > 0 ? this.state.selected_permission_group.includes(`WMS ${data.name}`): false} label={data.name} onChange={(checked) => this.multiCheckbox(checked, [`WMS ${data.name}`], "wms")} /> 
                        </div></Grid>
                      {data.permissions.map((res) => (
                          <Grid item lg={4} className="checkbox-body">
                            <div className="checkbox-body-div">     
                            <CustomCheckbox checked={this.state.selected_permission.length > 0 ? this.state.selected_permission.includes(res.id): false} label={res.name} onChange={(checked) => this.toggleCheckbox(checked, [res.id], `WMS ${data.name}`)} />
                            </div>
                          </Grid>
                      ))}
                      </Grid>
                      </fieldset>
                )
              ))}
        </div>
    </div>
</div>
  }

  render() {
    return <Box className="dashboard-page">
      <form noValidate autoComplete="off" onSubmit={this.handleSubmit}>
      <Grid container >
      <Box clone m={3}>
          <Grid item xs={3} >
              <CustomInput
                  label={'Role Name'}
                  placeholder={'Requiried'}
                  value={this.state.role_name}
                  error={this.state.role_nameValidated}
                  required={true}
                  autoFocus={true}
                  onChange={(e) => {
                      this.setState({
                        role_name: e.target.value,
                        role_nameValidated: (e.target.value !== '') ? false : true,
                      });
                  }}
              />
              
          </Grid>
      </Box>
      {(this.state.id === 0) && 
        <React.Fragment><Box mt={8} mr={3}><Grid   item xs={1}><Typography variant="body1" >or</Typography></Grid></Box>
      
        <Box clone mt={7} >
            <Grid item xs={3}>
              <CustomButton 
                className={'create-role-step'}
                type={'button'}
                color={'secondary'}
                isLoading={this.state.isLoading}
                onClick={() => {
                  this.setState({
                    openDialog: true,
                  });
                }}
              >
                <Box pl={2} pr={2}>Duplicate From...</Box>
              </CustomButton>
            </Grid>
        </Box></React.Fragment> 
      }
      
      </Grid>
      
      
      
      <Card className={'job-listing-step'}>
      <Typography variant="h5" >Role Permissions</Typography>
        {this.customTable()}
      </Card>
      <Box clone m={3} mb={3}>
          <Grid item xs={'auto'}>
            <CustomButton 
              className={'create-role-step'}
              type={'submit'}
              color={'secondary'}
              isLoading={this.state.isLoading}
            >
              <Box pl={2} pr={2}>Submit</Box>
            </CustomButton>
          </Grid>
      </Box>
      </form>
      {this.customDialog()}
    </Box>;
  }
}

export default AddRole;
